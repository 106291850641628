import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/hotel-resorts.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Hotels & Resorts | Camelo"
        metaDescription="Streamline scheduling and staff management for hotels & resorts. Easily track your employees' work and schedule shifts even during the busiest seasons."
        header="Streamline scheduling and staff management for hotels."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
